<template>
    <v-container fluid>
        <v-form @submit.prevent="saveFitnessCalendar">
            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                <v-card flat>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-row class="flex-nowrap">
                                    <v-col cols="1" style="max-width: 80px">
                                        <v-btn :to="{name: 'fitness_calendar'}" class="back-btn" link large>
                                            <v-icon>mdi-less-than</v-icon>
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="11">
                                        <v-subheader class="headline">{{ heading }}</v-subheader>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="py-0" cols="12">
                                <ValidationProvider ref="day_week" rules="required"
                                                    v-slot="{ errors, valid }">
                                    <v-select v-model="day_week" :items="itemDayWeek"
                                              :error="!valid"
                                              :error-messages="errors" :disabled="loadingDayWeek" item-text="name"
                                              item-value="id" return-object
                                              prepend-icon="mdi-calendar" :label="$t('day_week')"
                                              clearable></v-select>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12">
                                <ValidationProvider ref="group_lesson" rules="required"
                                                    v-slot="{ errors, valid }">
                                    <v-select v-model="group_lesson" :items="itemGroupLesson"
                                              :error="!valid"
                                              :error-messages="errors" :disabled="loadingGroupLesson" item-text="name"
                                              item-value="id" return-object
                                              prepend-icon="mdi-account-multiple-outline" :label="$t('group_lesson')"
                                              clearable></v-select>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12">
                                <ValidationProvider ref="trainer" rules="required"
                                                    v-slot="{ errors, valid }">
                                    <v-select v-model="trainer" :items="itemTrainer"
                                              :error="!valid"
                                              :error-messages="errors" :disabled="loadingTrainer" item-text="name"
                                              item-value="id" return-object
                                              prepend-icon="mdi-account-box-outline" :label="$t('trainer')"
                                              clearable></v-select>
                                </ValidationProvider>
                            </v-col>

                            <v-col class="py-0" cols="12" sm="6">
                                <v-menu v-model="TimeFromMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <ValidationProvider ref="time_from" name="time_from" rules="required|min:4|max:5" v-slot="{ errors, valid }">
                                            <v-text-field v-model="time_from" :error-messages="errors"
                                                          :error="!valid"
                                                          :disabled="loading" :label="$t('time_from')"
                                                          prepend-icon="mdi-clock-outline" color="primary" readonly v-on="on"

                                                          clearable @click:clear="time_from = null"></v-text-field>
                                        </ValidationProvider>
                                    </template>
                                    <v-time-picker ref="picker" v-model="time_from"
                                                   format="24hr"
                                                   :max="time_to ? time_to : ''"
                                                   :locale="lang" @change="TimeFromMenu = false"></v-time-picker>
                                </v-menu>


                            </v-col>

                            <v-col class="py-0" cols="12" sm="6">
                                <v-menu v-model="TimeToMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <ValidationProvider ref="time_to" name="time_to" rules="required|min:4|max:5" v-slot="{ errors, valid }">
                                            <v-text-field v-model="time_to" :error-messages="errors" :error="!valid"
                                                          :disabled="loading" :label="$t('time_to')"
                                                          prepend-icon="mdi-clock-outline" color="primary" readonly v-on="on"
                                                          clearable
                                                          @click:clear="time_to = null">

                                            </v-text-field>
                                        </ValidationProvider>
                                    </template>
                                    <v-time-picker ref="time_to" v-model="time_to"
                                                   format="24hr"
                                                   :min="time_from ? time_from : ''"
                                                   :locale="lang"
                                                   @change="TimeToMenu = false">

                                    </v-time-picker>
                                </v-menu>

                            </v-col>

                        </v-row>
                        <v-row>
                            <v-col class="py-0 d-flex justify-start" cols="12" sm="6">
                                <v-switch v-model="deleted" :disabled="loading"
                                          :label="$t('deleted')"
                                          @change="deleted ? active = false : active"
                                          color="red" hide-details></v-switch>
                            </v-col>
                            <v-col class="py-0 d-flex justify-start justify-sm-end" cols="12" sm="6">
                                <v-switch v-model="active" :disabled="loading || deleted"
                                          :label="$t('published')"
                                          color="primary" hide-details></v-switch>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="pt-6">
                        <v-progress-linear v-if="!$vuetify.breakpoint.xsOnly" v-model="progress"
                                           :active="loading" class="mx-2"></v-progress-linear>
                        <v-spacer v-if="!$vuetify.breakpoint.xsOnly"></v-spacer>
                        <v-btn type="submit" :disabled="invalid || loading" :loading="loading"
                               :block="$vuetify.breakpoint.xsOnly" color="primary">
                            {{ $t('save') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </ValidationObserver>
        </v-form>
    </v-container>
</template>
<script>

import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {mapGetters} from "vuex";

export default {
    name: 'FitnessCalendarForm',
    components: {
        ValidationProvider,
        ValidationObserver
    },
    inject: ['forceRerender'],
    data() {
        return {
            heading: null,
            exist_translations: {},
            all_translations: true,
            loadingDayWeek: false,
            day_week: null,
            itemDayWeek: [],
            loadingTrainer: false,
            trainer: null,
            itemTrainer: [],
            loadingGroupLesson: false,
            group_lesson: null,
            itemGroupLesson: [],
            progress: 0,
            loading: false,
            tab: 0,
            language: null,
            id: null,
            time_from : null,
            TimeFromMenu: false,
            time_to : null,
            TimeToMenu: false,
            format: {
                type: String,
                default: 'ampm'
            },
            active: false,
            deleted: false,
        }
    },
    computed: {
        ...mapGetters(['listLanguages',  'lang']),
        languages() {
            return this.listLanguages
        },
    },
    mounted() {
        this.language = this.languages[this.tab]
        this.checkCreate()
        this.getDayWeek()
        this.getTrainer()
        this.getGroupLesson()
    },
    methods: {
        setLanguage(val) {
            this.language = this.languages[this.tab]
            this.checkCreate()
        },
        checkCreate() {
            if (this.$route.name === "fitness_calendar.create") {
                this.heading = this.$t('fitness_calendar_creation')
            } else {
                this.heading = this.$t('fitness_calendar_editing')
                if (this.$route.params.id) {
                    this.getFitnessCalendar()
                }
            }
        },
        async getDayWeek() {
            this.loadingDayWeek = true;
            let params = {}
            if (this.language) {
                params.language = this.language
            }
            params.filter =  "search";
            await this.$http
                .get(`partner/day_week/`, {params: params})
                .then(res => {
                    this.itemDayWeek = res.body.data
                })
                .catch(err => {
                    this.$toastr.error(this.$t('messages.failed_to_get_list_day_weeks'));
                    this.itemDayWeek = []

                })
                .finally(end => {
                    this.loadingDayWeek = false
                });
        },
        async getTrainer() {
            this.loadingTrainer = true;
            let params = {}
            if (this.language) {
                params.language = this.language
            }
            params.filter =  "select";
            await this.$http
                .get(`partner/trainer/`, {params: params})
                .then(res => {
                    this.itemTrainer = res.body.data
                })
                .catch(err => {
                    this.$toastr.error(this.$t('messages.failed_to_get_list_trainers'));
                    this.itemTrainer = []

                })
                .finally(end => {
                    this.loadingTrainer = false
                });
        },
        async getGroupLesson() {
            this.loadingGroupLesson = true;
            let params = {}
            if (this.language) {
                params.language = this.language
            }
            params.filter =  "select";
            await this.$http
                .get(`partner/group_lesson/`, {params: params})
                .then(res => {
                    this.itemGroupLesson = res.body.data
                })
                .catch(err => {
                    this.$toastr.error(this.$t('messages.failed_to_get_list_group_lessons'));
                    this.itemGroupLesson = []

                })
                .finally(end => {
                    this.loadingGroupLesson = false
                });
        },
        async getFitnessCalendar() {
            var _this = this;
            this.progress = 0
            this.loading = true
            let params = {}
            if (this.language) {
                params.language = this.language
            }
            await this.$http
                .get(`partner/fitness_calendar/${this.$route.params.id}`, {
                    params: params,
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.day_week = res.body.data.day_week_id
                    this.group_lesson = res.body.data.group_lesson_id
                    this.trainer = res.body.data.trainer_id
                    this.time_from = res.body.data.time_from
                    this.time_to = res.body.data.time_to
                    this.active = res.body.data.active
                    this.deleted = res.body.data.deleted
                })
                .catch(err => {
                    this.$toastr.error(this.$t('failed_to_get_fitness_calendar'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })
        },
        async saveFitnessCalendar() {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()
            if (this.language) {
                formData.append('language', this.language)
            }
            if (this.time_from) {
                formData.append('time_from', this.time_from)
            }
            if (this.time_to) {
                formData.append('time_to', this.time_to)
            }
            if (this.active) {
                formData.append('active', 1)
            }
            if (this.deleted) {
                formData.append('deleted', 1)
            }
            if (this.group_lesson) {
                if (this.group_lesson.id) {
                    formData.append('group_lesson', this.group_lesson.id)
                } else {
                    formData.append('group_lesson', this.group_lesson)
                }
            }
            if (this.trainer) {
                if (this.trainer.id) {
                    formData.append('trainer', this.trainer.id)
                } else {
                    formData.append('trainer', this.trainer)
                }
            }
            if (this.day_week) {
                if (this.day_week.id) {
                    formData.append('day_week', this.day_week.id)
                } else {
                    formData.append('day_week', this.day_week)
                }
            }



            if (this.$route.params.id) {
                await this.$http
                    .put(`partner/fitness_calendar/${this.$route.params.id}`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('fitness_calendar_has_been_updated'))
                        if (res && res.body && res.body.data && res.body.data.exist_translations) {
                            this.exist_translations = res.body.data.exist_translations
                            this.all_translations = res.body.data.all_translations
                        }
                        //this.forceRerender()
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('fitness_calendar_has_not_been_updated'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            } else {
                // Add
                await this.$http
                    .post('partner/fitness_calendar', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('fitness_calendar_has_been_added'))
                        if (res && res.body && res.body.data && res.body.data.id) {
                            this.exist_translations = res.body.data.exist_translations
                            this.all_translations = res.body.data.all_translations
                            this.$router.push({
                                name: 'fitness_calendar.edit',
                                params: {
                                    id: res.body.data.id
                                }
                            })
                        } else {
                            this.$router.push({
                                name: 'fitness_calendar'
                            })
                        }
                        //this.forceRerender()
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('fitness_calendar_has_not_been_added'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            }
        }
    }
}
</script>
